import { WINDOW } from '@ng-toolkit/universal';
import { Component, OnInit , Inject, ElementRef, HostListener, OnDestroy} from '@angular/core';
import { fadeAnimation } from './animations';
import { environment } from '../environments/environment';
import { ContentService } from './services/content/content.service';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
// import { ConsoleReporter } from 'jasmine';
import { Subscription, throwError } from 'rxjs';
import { BookingService } from './services/booking/booking.service';
import {UserAccountService} from './services/user-account/user-account.service'
import { DealsService } from './services/deals/deals.service';
import { NetcoreService } from './services/netcore/netcore.service';
import { CleverTapService } from './services/clevertap/clevertap.service';
import { HttpClient} from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
declare var gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation]
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private el: ElementRef,
    @Inject(WINDOW) private window: Window, 
    private router: Router,
    private ContentService: ContentService,
    private bookingService:BookingService,
    private userService:UserAccountService,
    private DealsService:DealsService,
    private activatedRoute: ActivatedRoute,
    private NetcoreService:NetcoreService,
    private CleverTapService:CleverTapService,
    private http: HttpClient
  ) {
    this.router.events.subscribe((ev) => {
      
      if (ev instanceof NavigationStart) {
        
        this.userService.getLoggedInUser.subscribe(data=>{

          let token = '';
          if(data){
            token =  '?api_token='+data.data;
            let CT_DATA = {
              "Site": {
                'Name': data['user']['first_name'] + " " + data['user']['last_name'],
                'Identity':  data['user']['id'],
                'Email': data['user']['email'],
                'Phone': "+63"+data['user']['contact_number'],
                // 'DOB':  data['user']['birthday']
              }
            }
            this.CleverTapService.onUserLogin(CT_DATA);
            // Set the logged in user
            gtag('set', {
              'user_id': data['user']['id'],
            });
            // Set the logged in user's data
            gtag('set', 'user_properties', {
              'user_first_name': data['user']['first_name'],
              'user_last_name': data['user']['last_name'],
              'user_email': data['user']['email']
            });
          } else {
            if(!(this.userInfo == null || this.userInfo == 'null')) {
              let info = JSON.parse(this.userInfo);
              let CT_DATA = {
                "Site": {
                  'Name': info['firstname'] + " " + info['lastname'],
                  'Email': info['email_address'],
                  'Phone': "+63"+info['mobile_number'],
                }
              }
              this.CleverTapService.onUserLogin(CT_DATA);
            } 
          }
          if(!(this.bagId == null || this.bagId == 'null')) {
            this.DealsService.getBagItems(this.bagId,token).subscribe((data: {}) => {
              if(data['success'] == true) {
                localStorage.setItem('bagCount',data['data']['deals'].length);
                this.DealsService.changeCartCount(data['data']['deals'].length);
              } else {
                localStorage.removeItem('bagCount');
                localStorage.removeItem('bagId');
                localStorage.removeItem('buyerInfo');
              }
            },
            err => {
              console.log(err);
              localStorage.removeItem('bagCount');
              localStorage.removeItem('bagId');
              localStorage.removeItem('buyerInfo');
            });
          }
        });


    }

      // update user data if browser refreshed
      if (ev instanceof NavigationEnd) {
        // browserRefresh = !router.navigated;
        let visible = true;
        const el = this.el.nativeElement;
        const app_root = el.querySelector('.app-root'); 
        this.mobile_nav_hidden.forEach(route => {
          if(route['type'] == 'full') {
            if (this.router.url === route['route']){
              visible = false;
            }
          } else {
            if (this.router.url.includes(route['route'])){
              visible = false;
            }
          } 
        });
        if(app_root != null) {
          if(visible && !(this.router.url.includes('/beauty-bash'))) {
            app_root.classList.add("has-offset-bottom-nav");
          } else {
            app_root.classList.remove("has-offset-bottom-nav");
          }
        }
      }

    });
  }
  title = 'Parlon';
  prod = environment.production;
  updated_ui = false;
  contentLoaded = false;
  data;
  bagId = localStorage.getItem('bagId');
  netcoreId = localStorage.getItem('netcoreId');
  userInfo:any = localStorage.getItem('buyerInfo');

  mobile_nav_hidden = [
    {
      'route': '/beauty-bash',
      'type': 'dynamic'
    },
  ];

  $gcashSub:Subscription|undefined;  
  ngOnDestroy(){
    if(this.$gcashSub)
      this.$gcashSub.unsubscribe();    
  }

  // @HostListener('scroll', ['$event']) // for scroll events of the current element
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    const el = this.el.nativeElement;
    try {
      let navbar = el.querySelector('.navbar-root'); 
      let scrollTargetPosition = 150;  

      if (window.scrollY > scrollTargetPosition) {
        navbar.classList.add("is-scrolled");
        if(this.router.url == '/all-parlon-deals' && this.window.innerWidth <= 768)
         navbar.classList.remove("is-scrolled");
        scrollTargetPosition = 0;
      } else {
        navbar.classList.remove("is-scrolled");
        scrollTargetPosition = 150;
      }
    } catch(e) { }
  }

  ngOnInit() {
    this.getFeatures();
    this.getGlobalContent();
    this.getOgContent();

    this.$gcashSub = this.activatedRoute.queryParams.subscribe(params => {  
       if( params['utm_source']){
        let utm_source = params['utm_source'];
        let transaction_id= params['transaction_id'];
        // alert(1);
        localStorage.setItem('shopBack',JSON.stringify({
          date: new Date(),
          utm_source,
          transaction_id
        }) );
        // this.router.navigate(['/all-parlon-deals']);
       } 
      
      if(params['gcashonly'] == 'true'){
        // alert(1);
        localStorage.setItem('gcashOnly', JSON.stringify({gcashonly:true}));
      }            
    });
    
  }

  onActivate(event) {
    this.window.scroll(0, 0);
  }

  prepareRouteTransition(outlet) {
    const animation = outlet.activatedRouteData['animation'] || {};
    return animation['value'] || null;
  } 


  getGlobalContent() {
    let page = this;
    this.ContentService.getContentGlobal().subscribe((data: {}) => {
      page.ContentService.updateContent(data['data']);
      page.contentLoaded = true;
    });
  }

  getOgContent() {
    let page = this;
    this.ContentService.getOgContent().subscribe((data: {}) => {
      page.ContentService.updateOgContent(data);
    });
  }

  getFeatures() {
    let page = this;
    this.ContentService.getFeatures().subscribe((data: {}) => {
      page.ContentService.updateFeaturesAvailability(data);
    });
  }

  private makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
}
