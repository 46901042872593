import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
@Component({
  selector: 'static-loader',
  templateUrl: './static-loader.component.html',
  styleUrls: ['./static-loader.component.scss']
})
export class StaticLoaderComponent implements OnInit {
  resource = environment.resource;
  constructor() { }

  ngOnInit() {
  }

}
