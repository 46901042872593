import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'featured-parlons-loader',
  templateUrl: './featured-parlons-loader.component.html',
  styleUrls: ['./featured-parlons-loader.component.scss']
})
export class FeaturedParlonsLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
