import { Component, Input } from '@angular/core';

@Component({
  selector: 'header-title',
  templateUrl: './header-title.component.html',
  styleUrls: ['./header-title.component.scss']
})
export class HeaderTitleComponent {

  constructor() { }

  @Input()
  title: string;
  @Input()
  icon: string;
  @Input()
  iconHeight: number;
  @Input()
  alignment: string;
}
