import { Component, OnInit } from '@angular/core';
import { UserAccountService } from '../../services/user-account/user-account.service'
import { environment } from '../../../environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { ContentService } from '../../services/content/content.service';

@Component({
  selector: 'app-og-floating-button',
  templateUrl: './og-floating-button.component.html',
  styleUrls: ['./og-floating-button.component.scss']
})
export class OgFloatingButtonComponent implements OnInit {

  constructor(
    private UserAccountService: UserAccountService,
    private ContentService: ContentService,
    private router: Router
  ) { }

  isMember = false;
  teaser = environment.teaser;
  features: any = [];
  visible:boolean = false;
  
  hidden = [
    {
      'route': '/beauty-bash',
      'type': 'dynamic'
    },
  ];

  ngOnInit() {
    let app = this;
    this.UserAccountService.getLoggedInUser.subscribe(data=>{
      if(data != null) {
        if(data['og']['member'] == true) {
          app.isMember = true;
        } else {
          app.isMember = false;
        }
      }
    });
    this.checkRoutesHidden();
    this.ContentService.getFeatures().subscribe((data) => {
      this.features = data;
    });

    this.router.events.subscribe(event => {
      this.checkRoutesHidden();
    });
  }

  checkRoutesHidden() {
    let visible = true;
    this.hidden.forEach(route => {
      if(route['type'] == 'full') {
        if (this.router.url === route['route']){
          visible = false;
        }
      } else {
        if (this.router.url.includes(route['route'])){
          visible = false;
        }
      } 
    });

    this.visible = visible;
    // return visible;
  }

}
