import { Component, OnInit,  OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { RestService } from '../../services/rest.service';
import { SharedService } from '../../services/shared.service';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit, OnDestroy {

  constructor(private router: Router, private rest: RestService, private _shared: SharedService) { }

  inputSearch: string = "";
  locationSelected: any = '';
  areas: any = [];
  toStr = JSON.stringify;

  /** control for the MatSelect filter keyword */
  public areaFilterCtrl: FormControl = new FormControl();

  /** list of areas filtered by search keyword */
  public filteredAreas: ReplaySubject<{}[]> = new ReplaySubject<{}[]>(1);

  protected _onDestroy = new Subject<void>();

  ngOnInit() {
    this.rest.getLocations().subscribe((data: {}) => {
      this.areas = data['data'];
      this.filteredAreas.next(this.areas);
    });

    this._shared.searchAreaValue.subscribe(area => {
      this.locationSelected = area;
    });

    this.areaFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterAreas();
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  searchSubmit() {
    this._shared.changeSearchArea(this.locationSelected);
    let location = (this.locationSelected != "" ? JSON.parse(this.locationSelected) :  null );
    let slug = location != null ? location['slug'] : "all";

    if(this.inputSearch != '')
      this.router.navigate(['/search',slug,this.inputSearch]);
    else 
      this.router.navigate(['/search',slug]);
  }

  onSelectValueChange(value){
    this._shared.changeSearchArea(this.locationSelected);
  }

  protected filterAreas() {
    if (!this.areas) {
      return;
    }

    // get the search keyword
    let search = this.areaFilterCtrl.value;
    if (!search) {
      this.filteredAreas.next(this.areas);
      return;
    } else {
      search = search.toLowerCase();
    }
    
    // filter the areas
    this.filteredAreas.next(
      this.areas.filter(area => area.location_name.toLowerCase().indexOf(search) > -1)
    );
  }

}
