import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from '../../../../../services/rest.service';

@Component({
  selector: 'browse-featured-parlons-grid',
  templateUrl: './browse-featured-parlons-grid.component.html',
  styleUrls: ['./browse-featured-parlons-grid.component.scss']
})
export class BrowseFeaturedParlonsGridComponent implements OnInit {

  parlons:any = [];
  resourceLink = this.rest.resourceLink();
  fields = "?fields=logo";
  isLoaded = false;

  constructor(
    private rest: RestService, 
    private route: ActivatedRoute, 
    private router: Router
  ) { }

  ngOnInit() {
    this.getParlons();
  }

  
  getParlons() {
    let app = this;
    this.parlons = [];
    this.rest.getParlons(this.fields).subscribe((data: {}) => {
      this.parlons = data['data'];
      this.parlons.sort(() => Math.random() - 0.5);
      app.isLoaded = true;
    });
  }
}
