import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tab-panel',
  templateUrl: './tab-panel.component.html',
  styleUrls: ['./tab-panel.component.scss']
})
export class TabPanelComponent implements OnInit {

  constructor() { }

  @Input()
  parlon: any[];
  @Input()
  branch: any[];

  ngOnInit() {
  }

}
