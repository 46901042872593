import { Component, OnInit } from '@angular/core';
import { RestService } from '../../../../services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  categories: any = [];
  resourceLink = this.rest.resourceLink();
  fields = "?fields=category_name,image_main,slug";
  isLoaded = false;

  constructor(private rest: RestService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.getCategories();
  }

  getCategories() {
    let page = this;
    page.isLoaded = false;
    this.categories = [];
    this.rest.getCategories(this.fields).subscribe((data: {}) => {
      this.categories = data['data'];
      page.isLoaded = true;
    });
  }

}
