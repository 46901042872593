import { ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Scroll } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'filter-buttons',
  templateUrl: './filter-buttons.component.html',
  styleUrls: ['./filter-buttons.component.scss']
})
export class FilterButtonsComponent implements OnInit {

  @Input() filters = [];
  selected;
  filter_type = '';
  grabonly = '';

  constructor(
    private route: ActivatedRoute,
    private scroll: ViewportScroller,
    private router: Router
  ) { 
    this.route.queryParams.subscribe(params => {
      this.filter_type = params.filter_type,
      this.grabonly = params.grabonly
    });
  }

  ngOnInit() {
  }
  
  scrollTo(value) {
    this.selected = value;

    if(!this.grabonly) {
      this.router.navigate([], { 
        queryParams: { 
          filter_type: this.filter_type },
        fragment: this.selected
      });
    }

    this.router.navigate([], { 
      queryParams: { 
        filter_type: this.filter_type,
        grabonly:  this.grabonly },
      fragment: this.selected
    });
  }

}
