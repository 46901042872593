import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'home-ad-banner',
  templateUrl: './ad-banner.component.html',
  styleUrls: ['./ad-banner.component.scss']
})
export class AdBannerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
