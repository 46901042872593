import { Component, OnInit, Inject} from '@angular/core';
import { RestService } from '../../../../services/rest.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'home-featured-blogs',
  templateUrl: './featured-blogs.component.html',
  styleUrls: ['./featured-blogs.component.scss']
})
export class FeaturedBlogsComponent implements OnInit {

  constructor(
    private route: ActivatedRoute, 
    private rest: RestService
  ) { }

  slug: string;
  blogs: any = [];
  isLoaded = false;
  resourceLink = this.rest.resourceLink();

  ngOnInit() {
    this.getFeaturedBlogs();
  }

  getFeaturedBlogs() {
    let page = this;
    this.rest.getFeaturedBlogs().subscribe((data: {}) => {
      this.blogs = data['data'];
      // console.log(this.blogs)
      page.isLoaded = true;
      
    });
  }
}




