import { Component, OnInit, Input, ChangeDetectorRef , AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { UserAccountService } from '../../../../services/user-account/user-account.service';

@Component({
  selector: 'bag-details',
  templateUrl: './bag-details.component.html',
  styleUrls: ['./bag-details.component.scss'],
})
export class BagDetailsComponent implements OnInit, OnChanges{

  @Input() data:any;

  @Input() candiesToEarnData:any ;
  isLoggedIn = false;

  totalCandiesToEarn:number = 0; 
  constructor(private userService:UserAccountService, private cdr: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(()=>{
      if(this.candiesToEarnData){
        let total = 0;
        this.candiesToEarnData.forEach((item)=>{
          total += item.earnings;
        });    
        this.totalCandiesToEarn = total;
        this.cdr.detectChanges(); // Manually trigger change detection       
      }      
    },0)
  }

  ngOnInit() {
    this.userService.getLoggedInUser.subscribe(data=>{
      if(data != null){
        this.isLoggedIn = true;
      }               
    });
  }

}
