import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RestService } from '../../services/rest.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.scss']
})
export class PromoCodeComponent implements OnInit {

  loading = false;
  submitted = false;
  hide = true;
  data: object;
  partners: any = [];

  @Output() promoCode = new EventEmitter();

  @Input() promoFormGroup: FormGroup;
  hasValidCode = false;
  activeCode = '';
  message = '';
  resourceLink = this.rest.resourceLink();

  constructor(
    private _formBuilder: FormBuilder,
    private rest: RestService,
    private _shared: SharedService
  ) { }

  ngOnInit() {
    this.promoFormGroup = new FormGroup({
      'promoFormGroup': new FormGroup({
        'promoCode': new FormControl(null)
      })
    })
  }

  applyCode() {
  }

  removeCode() {
  }

   // convenience getter for easy access to form fields
   get f() {
    return this.promoFormGroup.controls;
  }

}
