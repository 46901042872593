import { Component, Input } from '@angular/core';
import { RestService } from '../../../../../services/rest.service';

@Component({
  selector: 'category-cards',
  templateUrl: './category-cards.component.html',
  styleUrls: ['./category-cards.component.scss']
})
export class CategoryCardsComponent {

  resourceLink = this.rest.resourceLink();

  constructor(private rest: RestService) { }

  @Input()
  data: any[];

  ngOnInit() {
  }

}
