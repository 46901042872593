import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'featured-deal',
  templateUrl: './featured-deal.component.html',
  styleUrls: ['./featured-deal.component.scss']
})
export class FeaturedDealComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
