import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'home-parlon-business',
  templateUrl: './parlon-business.component.html',
  styleUrls: ['./parlon-business.component.scss']
})
export class ParlonBusinessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
