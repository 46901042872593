import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wm-nonparticipant',
  templateUrl: './wm-nonparticipant.component.html',
  styleUrls: ['./wm-nonparticipant.component.scss']
})
export class WmNonparticipantComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
