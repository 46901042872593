import { Component, OnInit, Input } from '@angular/core';
import { RestService } from '../../../../services/rest.service';
import { NgxSiemaOptions, NgxSiemaService } from 'ngx-siema';

@Component({
  selector: 'wm-deals',
  templateUrl: './wm-deals.component.html',
  styleUrls: ['./wm-deals.component.scss']
})
export class WmDealsComponent implements OnInit {

  constructor(private rest:RestService, private ngxSiemaService: NgxSiemaService) { }

  @Input()
  deals: any[];
  resourceLink = this.rest.resourceLink();

  ngOnInit() {
  }

}
