import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-no-redeem',
  templateUrl: './no-redeem.component.html',
  styleUrls: ['./no-redeem.component.scss']
})
export class NoRedeemComponent implements OnInit {

  constructor() { }
  @Output() tabSelected = new EventEmitter<number>();

  selectTab() {
    this.tabSelected.emit(0);
  }

  ngOnInit() {
  }

}
