import { AfterViewInit, Component, ElementRef, HostListener, OnInit, QueryList, ViewChildren, ViewChild } from '@angular/core';
import { RestService } from '../../services/rest.service';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { distinctUntilKeyChanged, filter, publishReplay, refCount, share, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import * as Rx from 'rxjs';

export interface Sort {
  value: string;
  viewValue: string;
  sequence: string;
}

@Component({
  selector: 'app-all-parlon-deals',
  templateUrl: './all-parlon-deals.component.html',
  styleUrls: ['./all-parlon-deals.component.scss']
})
export class AllParlonDealsComponent implements AfterViewInit, OnInit {

  sort: Sort[] = [
    { value: 'category', viewValue: 'CATEGORY', sequence : '' },
    { value: 'parlon', viewValue: 'PARLON', sequence : '' },
    { value: 'savings', viewValue: 'Savings (from low to high)' , sequence : 'asc'},
    { value: 'savings', viewValue: 'Savings (from high to low)' , sequence : 'desc' },
    { value: 'price', viewValue: 'Price (from low to high)', sequence : 'asc' },
    { value: 'price', viewValue: 'Price (from high to low)', sequence : 'desc' }
  ];

  isLoaded = false;
  dealsLoaded = false;
  allLocationsSelected=false;
  dealsLocations: any = [];
  filteredLocations: any = [];
  test = 0;
  resourceLink = this.rest.resourceLink()
  banner: any = [];
  grabOnly: string;
  getGrabParam = localStorage.getItem('grabOnly')
 
  filteredDealSubject$ = new Rx.BehaviorSubject<Sort | undefined>(undefined);
  filteredDeal$ = this.filteredDealSubject$.asObservable();
  pageYOffset = 0;

  dealsSubject$ = new Rx.BehaviorSubject([]);
  deals$ = this.dealsSubject$.asObservable().pipe(filter(deals => !!deals.length));

  @ViewChildren('dealSection') dealSections: QueryList<ElementRef>;
  @ViewChild('select') select: MatSelect;

  constructor(
    private rest: RestService,
    private route: ActivatedRoute,
    private _shared: SharedService,
    private scroll: ViewportScroller,
    private router: Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const tree = this.router.parseUrl(this.router.url);
        if (tree.fragment) {
          const element = document.querySelector("#" + tree.fragment);
          if (element) { 
            element.scrollIntoView(true); 
          }
        }
      }
    });
  }

  @HostListener('window:scroll', ['$event']) 
  onScroll(event){
    this.pageYOffset = window.pageYOffset;
  }

  ngAfterViewInit() {
    this.deals$.pipe(take(1)).toPromise().then(deals => {
      const tree = this.router.parseUrl(this.router.url);
      if (tree.fragment) {
        this.dealSections.changes
          .pipe(filter(els => els.length), take(1))
          .toPromise()
          .then(els => {
            const element = els.find(el => el.nativeElement.id === tree.fragment);
            element.nativeElement.scrollIntoView();
          })
      }
    });
  }
  
  ngOnInit() {
    this.route.queryParams
      .pipe(take(1))
      .toPromise()
      .then(params => {
        const sortType = this.sort.find(filter => filter.value == params.filter_type);
        
        if (!params.filter_type || !sortType) {
          this.router.navigate([], { 
            queryParams: { 
              filter_type: this.sort[0].value },
              fragment: this.router.parseUrl(this.router.url).fragment,
          });
        }

        if(params.grabonly) {
          localStorage.setItem('grabOnly', params.grabonly);
          this.router.navigate([], {
            queryParams: {
              filter_type: this.sort[0].value,
              grabonly: params.grabonly 
            },
            fragment: this.router.parseUrl(this.router.url).fragment
          })
        } else {
          localStorage.clear()
        }
        

        this.filteredDealSubject$.next(sortType || this.sort[0]);
      });

    this.deals$ = this.filteredDeal$.pipe(
      filter(filteredDeal => filteredDeal !== undefined),
      distinctUntilKeyChanged('viewValue'),
      switchMap(
        filteredDeal =>
          filteredDeal.value == 'category'
            ? this.rest.getAllDealsByCategory()
            :
          filteredDeal.value == 'parlon'
            ? this.rest.getAllDealsByParlon()
            :
          (filteredDeal.value == 'price' || filteredDeal.value == 'savings') &&
            (filteredDeal.sequence == 'asc' || filteredDeal.sequence == 'desc')
            ? this.rest.getAllDealsBySortOptions(filteredDeal.value, filteredDeal.sequence)
            :
          Rx.of(undefined),
      ),
      tap(deals => {
        if (deals) {
          this.dealsLoaded = true;
        }
      }),
    ).pipe(
      shareReplay(1),
    );
    this.rest.getDealsLocations().subscribe((data: {}) => {
      this.dealsLocations = data;
    });
    this.getPageBanner();
  }
  
  sortByFilter(value) {
    const isOptionChanged = this.filteredDealSubject$.value !== value;
    if (isOptionChanged) {
      this.dealsLoaded = false;
    }
    this.filteredDealSubject$.next(value);
    this.router.navigate([], { 
      queryParams: { 
        filter_type: value.value },
      fragment: isOptionChanged
        ? undefined
        : this.router.parseUrl(this.router.url).fragment,
    });
  }

  sortByLocation(value) {
    this.filteredLocations = value;
  }

  checkLocationAvailability(locations) {
    let available = false;
    locations.forEach(e => {
      if(this.filteredLocations.includes(e))
        available = true;
    });
    return available;
  }

  checkLocationGroupAvailability(group) {
    let available = false;
    group.forEach(e => {
      if(this.checkLocationAvailability(e['locations']) || this.filteredLocations.length == 0)
        available = true;
    });
    return available;
  }

  getPageBanner() {
    let page = this;
    this.rest.getPageBanner().subscribe((data: {}) => {
      this.banner = data['data']
      page.isLoaded = true;
    });
  }

  toggleAllLocationsSelection(){
    if (this.allLocationsSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
    this.select.selectionChange;
  }

  optionLocationClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allLocationsSelected = newStatus;
  }

  

  scrollToTop(){
    this.scroll.scrollToPosition([0,0]);
  }

  trackDealById(_, deal) {
    return deal["id"];
  }
}
