import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-area-select',
  templateUrl: './area-select.component.html',
  styleUrls: ['./area-select.component.scss']
})
export class AreaSelectComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
