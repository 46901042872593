import { Component, OnInit, Input } from '@angular/core';
import { NgxSiemaOptions, NgxSiemaService } from 'ngx-siema';
import { RestService } from '../../../../../services/rest.service';

@Component({
  selector: 'category-slider',
  templateUrl: './category-slider.component.html',
  styleUrls: ['./category-slider.component.scss']
})
export class CategorySliderComponent implements OnInit {

  resourceLink = this.rest.resourceLink();

  constructor(private rest: RestService, private ngxSiemaService: NgxSiemaService) { }

  @Input()
  categories: any[];

  ngOnInit() {
  }

  options: NgxSiemaOptions = {
    selector: '.siema',
    duration: 200,
    easing: 'ease-out',
    perPage: {
      320: 2,
      768: 3,
      1024: 6,
    },
    startIndex: 0,
    draggable: true,
    threshold: 20,
    loop: true,
    onInit: () => {
      
    },
    onChange: () => {

    },
  };

}
