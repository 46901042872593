import { Component, Input, OnInit } from '@angular/core';
import { RestService } from '../../../services/rest.service';

@Component({
  selector: 'deals-card-sub-logo',
  templateUrl: './deals-card-sub-logo.component.html',
  styleUrls: ['./deals-card-sub-logo.component.scss']
})
export class DealsCardSubLogoComponent implements OnInit {

  @Input() deal = [];
  resourceLink = this.rest.resourceLink();

  constructor(
    private rest: RestService
  ) { }

  ngOnInit() {
    // console.log(this.deal)
  }

}
