import { Component, OnInit, Input } from '@angular/core';
import { RestService } from '../../services/rest.service';

@Component({
  selector: 'other-parlons-card',
  templateUrl: './other-parlons-card.component.html',
  styleUrls: ['./other-parlons-card.component.scss']
})
export class OtherParlonsCardComponent implements OnInit {

  resourceLink = this.rest.resourceLink();

  constructor(private rest: RestService) { }

  @Input()
  data: any[];

  ngOnInit() {
  }

}
