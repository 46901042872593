import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../../../../services/shared.service';

@Component({
  selector: 'branch-filter',
  templateUrl: './branch-filter.component.html',
  styleUrls: ['./branch-filter.component.scss']
})
export class BranchFilterComponent implements OnInit {

  constructor(
    private _shared: SharedService, 
    private router: Router) { }

  areas: any = [];
  branch: any = [];
  parlon: any = [];

  ngOnInit() {
    this._shared.currentAreas.subscribe(areas => this.areas = areas);
    this._shared.currentParlon.subscribe(parlon => this.parlon = parlon);
    this._shared.currentBranch.subscribe((branch ) => {
      this.branch = branch;
    });
  }

  onChange(value){
    this._shared.changeSelectedAreaID(value);
    this.parlon.branches.forEach(e => {
      if(e.location.id === value) {
        this.router.navigate(['/parlon/',this.parlon.slug,e.slug]);
      }
    });
  }

}
