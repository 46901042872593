import { Component, OnInit, Input } from '@angular/core';
import { RestService } from '../../services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'beauty-card',
  templateUrl: './beauty-card.component.html',
  styleUrls: ['./beauty-card.component.scss']
})
export class BeautyCardComponent implements OnInit {

  constructor(private rest:RestService, private route: ActivatedRoute, private router: Router) { }

  @Input() data: any = [];
  resourceLink = this.rest.resourceLink();

  ngOnInit() {
  }
}
