import { WINDOW } from '@ng-toolkit/universal';
import { Component, OnInit, HostListener , Inject, NgZone, OnDestroy, ChangeDetectorRef} from '@angular/core';
import { Router, Event, NavigationStart, ActivatedRoute, NavigationEnd } from '@angular/router';

import { AuthService } from '../../services/auth.service';
import { RestService } from '../../services/rest.service';
import { SharedService } from '../../services/shared.service';
import { Title, Meta } from '@angular/platform-browser'; 
import { User } from '../../_models/user.model';
import * as Rx from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, takeUntil } from 'rxjs/operators';

enum NavbarType {
  GLOBAL,
  SERVICES,
  DEALS,
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  NavbarType = NavbarType;
  categories: any = [];
  resourceLink = this.rest.resourceLink();
  fields = "?fields=category_name,slug";
  isLoaded = false;
  parlon: any = [];
  areas: any = [];
  branch: any = [];
  selectedArea;
  contact: any = [];
  deal: any = [];
  dealPrice: any = 0;
  branchByAreas = [];

  currentUser: User;
  users: User[] = [];

  private toggle : boolean = false;
  navbarVisible: boolean;
  navbarTypeSubject$ = new Rx.BehaviorSubject<NavbarType>(NavbarType.GLOBAL);
  navbarType$ = this.navbarTypeSubject$.asObservable();
  rxSubscription: Rx.Subscription;
  routesNavHidden = [
    // brb page index
    // {
    //   'route':'',
    //   'type':'dynamic'
    // },
    {
      'route':'/search',
      'type': 'dynamic'
    },
    {
      'route':'/mobile',
      'type': 'dynamic'
    },
    {
      'route':'/all-parlon-deals',
      'type': 'dynamic'
    },
    {
      'route':'/deal-purchase',
      'type':'dynamic'
    },
    {
      'route':'/login',
      'type':'full'
    },
    {
      'route':'/brb',
      'type':'full'
    },
    {
      'route':'/membership',
      'type':'dynamic'
    },
    {
      'route':'/membership-purchase',
      'type':'dynamic'
    },
    {
      'route': '/payments',
       'type': 'dynamic'
    },
    {
      'route': '/glife',
      'type': 'dynamic'
    },
    {
      'route':'/payment-purchase',
      'type':'dynamic'
    },
    {
      'route':'/register/email',
      'type':'full'
    },
    {
      'route':'/register/email/user',
      'type':'full'
    }, 
    {
      'route':'/verify',
      'type':'dynamic'
    },
    {
      'route':'/otp-success',
      'type':'dynamic'
    }
  ];

  public branchFilterCtrl: FormControl = new FormControl();

  /** list of areas filtered by search keyword */
  public filteredBranches: Rx.ReplaySubject<{}[]> = new Rx.ReplaySubject<{}[]>(1);

  protected _onDestroy = new Rx.Subject<void>();

  constructor(
    @Inject(WINDOW) private window: Window, 
    private rest: RestService, 
    private _shared: SharedService,
    private route: ActivatedRoute,
    public router: Router,  
    private _title: Title, 
    private _meta: Meta,
    private auth: AuthService,
    private ngZone: NgZone,
    private changeDetector: ChangeDetectorRef
    ) {
    
    this.auth.currentUser.subscribe(user => this.currentUser = user);

    this.router.events.subscribe((event: Event) => {
      const burger = document.querySelector('.navbar-nav');
      const menu = document.querySelector('.navbar');      
      this.toggle = false;

      if (event instanceof NavigationStart) {
        burger.classList.remove('is-active');
        menu.classList.remove('is-active'); 
      }
    });
  }

  ngOnInit() {
    Rx.combineLatest([
      this._shared.currentParlon.pipe(map(parlon => parlon as any)),
      this._shared.currentAreas,
    ]).subscribe(([parlon,areas]) => {
      this.areas = areas;
      this.parlon = parlon;
      this.branchByAreas = this.groupBranchByAreas(areas, parlon.branches);
      this.filteredBranches.next(this.branchByAreas);
    })

    // listen for search field value changes
    this.branchFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterAreas();
    })

    this._shared.selectedAreaID.subscribe(id => this.selectedArea = id);
    this._shared.currentdeal.subscribe(deal => this.deal = deal);
    this._shared.currentdealPrice.subscribe(price => this.dealPrice = price);
    this.getCategories();
    this.getVisiblePageSections();

    const burger = document.querySelector('.navbar-nav');
    const menu = document.querySelector('.navbar');

    this._shared.currentBranch.subscribe((branch) => {
      this.branch = branch;
      if(branch.length > 0) {
        this.contact = branch['contact_numbers'].split(";");
      }
    });
    this.rxSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const { url } = event;
        if (url.startsWith('/parlon/')) {
          this.navbarTypeSubject$.next(NavbarType.SERVICES);
        } else if (url.startsWith('/deals/checkout/')) {
          this.navbarTypeSubject$.next(NavbarType.DEALS);
        } else {
          this.navbarTypeSubject$.next(NavbarType.GLOBAL);
        }

        setTimeout(() => {
          let navbar = document.getElementById('navbar');
          let body_content = document.getElementById('bodyContent');
          const homepage = document.getElementById('homenav-checkpoint');
          if (homepage == null) {
            body_content.style.marginTop = navbar.offsetHeight + 'px';
          } else {
            body_content.style.marginTop = '';
          }
    
          try {
            let hasOffset = Array.from(document.getElementsByClassName('hasOffset') as HTMLCollectionOf<HTMLElement>);
            hasOffset.forEach((element) => {
              element.style.marginTop = '-' + navbar.offsetHeight + 'px';
            });
            // hasOffset.style.marginTop = '-' + navbar.offsetHeight + 'px';
          } catch (banner_error) { console.log("Banner error: " + banner_error) }
        }, 1000);
      }
    });
    burger.addEventListener('click', function () {
      burger.classList.toggle('is-active');
      menu.classList.toggle('is-active');
    });
  }

  isActive(location) {
    let active = ('/category/' + location == this.router.url);
    if(active) {
      return 'active';
    }
  }

  getRoute() {
    if (this.router.url.includes('/all-parlon-deals') || this.router.url.includes('/deals/checkout/')) {
      document.getElementById('fb-root').style.display = 'block';
    } else {
      document.getElementById('fb-root').style.display = 'none';
    }
    if (this.router.url.includes('/parlon/') ) {
      return "is-inner";
    } else if(this.router.url.includes('/parlon-beauty/')) {
      return "parlon-beauty";
    } else if(this.router.url.includes('/glife')){
      
    } else {
      this._title.setTitle( "Book and Buy the Best Salon and Wellness Deals | Parlon");
      this._meta.updateTag({ name: 'title', content: 'Book and Buy the Best  Salon and Wellness Deals Online' });
      this._meta.updateTag({ name: 'description', content: "The Philippines' go-to-platform for beauty and wellness services. Book in less than 30 seconds and get the best salon and wellness deals." });
    }
  
    if (this.router.url === '/') {
      return "show-bar-onscroll";
    } 
    if (this.checkIfHidden()) {
      return "hide-bar";
    }  
  }

  getCategories() {
    let page = this;
    page.isLoaded = false;
    this.categories = []; 
    this.rest.getCategories(this.fields).subscribe((data: {}) => {
      this.categories = data['data'];
      page.isLoaded = true;
    });
  }

  onChangeArea(value){
    this._shared.changeSelectedAreaID(value);
    this.parlon.branches.forEach(e => {
      if(e.location.id === value) {
        this.router.navigate(['/parlon/',this.parlon.slug,e.slug]);
      }
    });
  }

  onChangeBranch(event,value){
    // if (event.isUserInput) { // ignore on deselection of the previous option
    //   this.router.navigate(['/parlon/',this.parlon.slug,value]);
    // }
    this._shared.changeSelectedAreaID(value);
    this.parlon.branches.forEach(e => {
      if(e.id === value) {
        this.router.navigate(['/parlon/',this.parlon.slug,e.slug]);
      }
    });
  }

  scrollTo(): void {
    const element = document.querySelector("#partners");
    if(element) {
      element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
  }

  navToggler(event) {
    this.toggle = !this.toggle;
  }


  @HostListener('window:scroll', [])
  onWindowScroll() {
    const element = document.querySelector('.navbar');
    const navTab = document.querySelector('.navbar-nav-desktop');                // deprecated
    const searchBar = document.querySelector('.navbar-services-search-bar');
    const showBarOnScroll = "show-bar-onscroll";
    const hide = 'hide'
    const hideBar = "hide-navbar";
    // const hideSearchBar = "hide-search";                                      // deprecated
    
    const homeCheckpoint = document.getElementById('homenav-checkpoint');
    // const dealsCheckpoint = document.getElementById('deals-checkpoint');
    if (homeCheckpoint != null) {
      const elementOffsetTop = homeCheckpoint.offsetTop;
      if (elementOffsetTop <= this.window.pageYOffset) {
        element.classList.remove(showBarOnScroll);
      } else {
        this.toggle = false;
        element.classList.add(showBarOnScroll);
      }
    }

    if(this.checkIfHidden()) {
      this.toggle = false;
      element.classList.add(hideBar);
    }

    // Parlon partner mobile links
    const tab = document.querySelector('.parlon-tabs');
    const setVisible = "visible";
    const tabCheckpoint = document.getElementById('tab-checkpoint');
    if (tabCheckpoint != null) {
      const elementOffsetTop = tabCheckpoint.offsetTop;
      if (elementOffsetTop <= this.window.pageYOffset) {
        tab.classList.add(setVisible);
      } else {
        tab.classList.remove(setVisible);
      }
    }

    // Parlon deals mobile nav on scroll
    const mobile = document.querySelector('.mobile')
    const isScrolled = "is-scrolled";
    const dealsCheckpoint = document.getElementById('deals-checkpoint');
    if (dealsCheckpoint != null) {
      const elementOffsetTop = dealsCheckpoint.offsetTop;
      if (elementOffsetTop >= this.window.pageYOffset) {
        mobile.classList.remove(isScrolled);
      } else {
        mobile.classList.add(isScrolled);
      }
    }
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['/']);
  }

  getVisiblePageSections() {
    this.rest.homepageSectionVisibility().subscribe(data => {
      this.ngZone.run(() => {
        const sections = data['data'];

        this.navbarVisible = sections.find(
          ({ section }) => section === 'Registration and Sign up Bar'
        ).visible === 0;
      });
    })
  }

  checkIfHidden() {
    let isHidden = false;
    this.routesNavHidden.forEach(route => {
      if(route['type'] == 'full') {
        if (this.router.url === route['route']){
          isHidden = true;
        }
      } else {
        if (this.router.url.includes(route['route'])){
          isHidden = true;
        }
      } 
    });
    return isHidden;
  }

  protected filterAreas() {
    // get the search keyword
    let search = this.branchFilterCtrl.value;

    if (!this.parlon.branches) {
      return;
    }
    
    if (!search) {
      this.filteredBranches.next(this.branchByAreas);
      return;
    } else {
      search = search.toLowerCase().trim();
    }

    // filter the areas and branches
    this.filteredBranches.next(
      this.branchByAreas
        .map(area => {
          const showBranchesGroup = area.location_name.toLowerCase().indexOf(search) > -1;
          let filteredBranches = area.branches;

          if (!showBranchesGroup) {
            filteredBranches = filteredBranches.filter(
              branch => branch.branch_name.toLowerCase().indexOf(search) > -1,
            );
          }

          return {
            ...area,
            branches: filteredBranches,
          };
        })
        .filter(area => !!area.branches.length),
    );
  }

  private groupBranchByAreas(areas, branches) {
    return areas.map(area => ({
      ...area,
      branches: branches.filter(branch => branch.location.id == area.id),
    }));
  }

  ngOnDestroy() {
    if (this.rxSubscription) {
      this.rxSubscription.unsubscribe();
    }

    this._onDestroy.next();
    this._onDestroy.complete();
  }
 
}
