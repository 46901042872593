import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RestService } from '../../../../../services/rest.service';

@Component({
  selector: 'deals-category',
  templateUrl: './deals-category.component.html',
  styleUrls: ['./deals-category.component.scss']
})
export class DealsCategoryComponent implements OnInit {

  categories: any = [];
  resourceLink = this.rest.resourceLink();
  isLoaded = false;

  constructor(
    private rest: RestService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.getCategories();
  }

  getCategories() {
    let page = this;
    page.isLoaded = false;
    this.categories = [];
    this.rest.getAllDealsByCategory().subscribe(data => {
      this.categories = data;
      page.isLoaded = true;
    });
  }

}
