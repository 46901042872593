import { Component, OnInit, Input } from '@angular/core';
import { RestService } from '../../services/rest.service';

@Component({
  selector: 'partner-card',
  templateUrl: './partner-card.component.html',
  styleUrls: ['./partner-card.component.scss']
})
export class PartnerCardComponent implements OnInit {

  resourceLink = this.rest.resourceLink();

  constructor(private rest: RestService) { }

  @Input()
  data: any[];

  ngOnInit() {
  }

}
