import { Component, OnInit } from '@angular/core';
import { RestService } from '../../../../../services/rest.service';


@Component({
  selector: 'deals-card-list',
  templateUrl: './deals-card-list.component.html',
  styleUrls: ['./deals-card-list.component.scss']
})
export class DealsCardListComponent implements OnInit {

  constructor(
    private rest: RestService
  ) { }

  deals:any = [];
  isLoaded = false;

  ngOnInit() {
    this.getHomepageDeals();
  }

  getHomepageDeals() {
    let page = this;
    this.deals = [];
    this.rest.getHomepageDeals().subscribe((data: {}) => {
      this.deals = data;
      page.isLoaded = true;      
    });
  }

}
