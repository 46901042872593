import { Component, OnInit } from '@angular/core';
import { NgxSiemaOptions } from 'ngx-siema';
import { RestService } from '../../../../../services/rest.service';
import { NgxSiemaService } from 'ngx-siema';

@Component({
  selector: 'featured-services-slider',
  templateUrl: './featured-services-slider.component.html',
  styleUrls: ['./featured-services-slider.component.scss']
})
export class FeaturedServicesSliderComponent implements OnInit {

  services:any = [];
  isLoaded = false;

  constructor(private rest:RestService,private ngxSiemaService: NgxSiemaService) { }

  ngOnInit() {
    this.getServices();
  }

  options: NgxSiemaOptions = {
    selector: '.siema-services',
    duration: 200,
    easing: 'ease-out',
    perPage: {
      320: 1,
      768: 3,
      1024: 3,
    },
    startIndex: 0,
    draggable: true,
    threshold: 20,
    loop: false,
    onInit: () => {
      // runs immediately after first initialization
    },
    onChange: () => {
      // runs after slide change
    },
  };

  getServices() {
    let page = this;
    this.services = [];
    this.rest.getFeaturedServices().subscribe((data: {}) => {
      this.services = data['data'];
      page.isLoaded = true;
    });
  }

}
