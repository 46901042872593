import { Component, OnInit } from '@angular/core';
import { NgxSiemaOptions, NgxSiemaService } from 'ngx-siema';
import { RestService } from '../../../../../services/rest.service';

@Component({
  selector: 'deals-card-slider',
  templateUrl: './deals-card-slider.component.html',
  styleUrls: ['./deals-card-slider.component.scss']
})
export class DealsCardSliderComponent implements OnInit {

  constructor(
    private rest: RestService,
    private ngxSiemaService: NgxSiemaService
  ) { }

  deals:any = [];
  isLoaded = false;

  ngOnInit() {
    this.getHomepageDeals();
  }

  options: NgxSiemaOptions = {
    selector: '.siema-deals',
    duration: 200,
    easing: 'ease-out',
    perPage: {
      320: 1,
      768: 3,
      1280: 4
    },
    startIndex: 0,
    draggable: false,
    threshold: 20,
    loop: false,
    onInit: () => {
      // runs immediately after first initialization
    },
    onChange: () => {
      // runs after slide change
    },
  };

  prev() {
    this.ngxSiemaService.prev(1)
      .subscribe((data: any) => data);
  }
 
  next() {
    this.ngxSiemaService.next(1)
      .subscribe((data: any) => data);
  }

  getHomepageDeals() {
    let page = this;
    this.deals = [];
    this.rest.getHomepageDeals().subscribe((data: {}) => {
      this.deals = data;
      page.isLoaded = true;      
    });
  }
}
