import { Injectable } from '@angular/core';

declare var clevertap: any;

@Injectable({
  providedIn: 'root',
})
export class CleverTapService {
  constructor() {
    // 
    try {
      // Initialize CleverTap account ID
      clevertap.account.push({ id: 'ZRZ-K98-ZR7Z' });
    } catch (error) {
      
    }
    
  }

  // Track an event
  trackEvent(eventName: string, properties: any) {
    try {
      clevertap.event.push(eventName, properties);
    } catch (error) {
      
    }
    
  }

  // Update user profile
  updateUserProfile(profileData: any) {
    try {
      clevertap.profile.push(profileData);
    } catch (error) {
      
    }
    
  }

  // Notify user login
  onUserLogin(profileData: any) {
    try {
      clevertap.onUserLogin.push(profileData);
    } catch (error) {
      
    }
    
  }

  // Enable notifications (optional)
  enableNotifications() {
    clevertap.notifications.push({
      "titleText": "Would you like to receive notifications?",
      "bodyText": "We promise to send only relevant content.",
      "okButtonText": "Yes",
      "rejectButtonText": "No",
      "serviceWorkerPath": "./service-worker.js"
    });
  }
}