import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'beauty-bag-nav',
  templateUrl: './beauty-bag-nav.component.html',
  styleUrls: ['./beauty-bag-nav.component.scss']
})
export class BeautyBagNavComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
