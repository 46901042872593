import { AfterViewInit, Component, ElementRef, OnInit, Input } from '@angular/core';
import { RestService } from '../../../../services/rest.service';
import { NgxSiemaOptions, NgxSiemaService } from 'ngx-siema';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'promo-banner',
  templateUrl: './promo-banner.component.html',
  styleUrls: ['./promo-banner.component.scss']
})
export class PromoBannerComponent implements AfterViewInit, OnInit {

  resourceLink = this.rest.resourceLink();
  @Input() banners = [];
  isLoaded = false;
  
  constructor(
    private rest: RestService
  ) { }

  ngAfterViewInit() {
  }

  ngOnInit() {

  }
  config: SwiperOptions = {
    autoplay: {
      delay: 5000,
    },
    pagination: { 
      el: '.swiper-pagination', 
      clickable: true 
    },
    navigation: {
      // nextEl: '.swiper-button-next',
      // prevEl: '.swiper-button-prev'
    },
  };
}
