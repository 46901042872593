import { Component, OnInit } from '@angular/core';
import { RestService } from '../../../../services/rest.service';

@Component({
  selector: 'home-featured-deals',
  templateUrl: './featured-deals.component.html',
  styleUrls: ['./featured-deals.component.scss']
})
export class FeaturedDealsComponent implements OnInit {

  isLoaded = false;
  deals = [];
  banner = '';
  header = '';
  resourceLink = this.rest.resourceLink();

  constructor(
    private rest: RestService
  ) { }

  ngOnInit() {
    this.getPageBanner();
  }

  getPageBanner() {
    let page = this;
    this.rest.getPageBanner().subscribe((data: {}) => {
      page.banner = data['data']['event_banner'];
      page.header = data['data']['event_header_img'];
      page.isLoaded = true;
    });
  }

}
