import { Component, OnInit } from '@angular/core';
import { NgxSiemaOptions } from 'ngx-siema';
import { RestService } from '../../../../../services/rest.service';
import { NgxSiemaService } from 'ngx-siema';

@Component({
  selector: 'home-deals-slider',
  templateUrl: './deals-slider.component.html',
  styleUrls: ['./deals-slider.component.scss']
})
export class DealsSliderComponent implements OnInit {

  deals:any = [];
  isLoaded = false;

  constructor(
    private rest: RestService,
    private ngxSiemaService: NgxSiemaService) { 
  }

  ngOnInit() {
    this.getDeals();
  }

  options: NgxSiemaOptions = {
    selector: '.siema-deals',
    duration: 200,
    easing: 'ease-out',
    perPage: {
      320: 1,
      768: 3,
      1280: 4
    },
    startIndex: 0,
    draggable: false,
    threshold: 20,
    loop: false,
    onInit: () => {
      // runs immediately after first initialization
    },
    onChange: () => {
      // runs after slide change
    },
  };

  prev() {
    this.ngxSiemaService.prev(1)
      .subscribe((data: any) => data);
  }
 
  next() {
    this.ngxSiemaService.next(1)
      .subscribe((data: any) => data);
  }

  getDeals() {
    let page = this;
    this.deals = [];
    this.rest.homepageSectionVisibility().subscribe((data: {}) => {
      this.deals = data['eventData']['deals'];
      page.isLoaded = true;      
    });
  }

}
