import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor( private router: Router ) { }

  ngOnInit() {
  }

  hideContact() {
    if (this.router.url.includes('/parlon/') ) {
      return "d-none";
    }
  }

  getRoute() {
    let isHidden = false;
    let routesHidden = [
      '/login',
      '/register/email',
      '/register/email/user',
      '/deals',
      '/deal-purchase',
      '/membership',
      '/membership-purchase',
      '/payments',
      '/payment-purchase',
      '/glife',
      '/brb',
      '/search',
      '/mobile/search',
      '/verify',
      '/otp-success'
      // brb page index
      // '/'
    ];
    routesHidden.forEach(route => {
      if (this.router.url.includes(route)){
        isHidden = true;
      }
    });
    if (isHidden) {
      return "is-hidden";
    } 
  }

}
