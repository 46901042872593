import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';

@Component({
  selector: 'app-advisory',
  templateUrl: './advisory.component.html',
  styleUrls: ['./advisory.component.scss']
})
export class AdvisoryComponent implements OnInit {

  advisory = false;

  constructor(public router: Router) { }

  ngOnInit() {

  }
  
  removeAdvisory(e){
    sessionStorage.setItem("advisory", 'true');
    e.currentTarget.parentNode.remove();
  }

  isActive() {
    const active = sessionStorage.getItem("advisory");
    if(active != 'true' && !(this.router.url.includes('/parlon/')))
      return 'active';
    }

}
