import { Component, OnInit, Input } from '@angular/core';
import { RestService } from '../../services/rest.service';

@Component({
  selector: 'services-card',
  templateUrl: './services-card.component.html',
  styleUrls: ['./services-card.component.scss']
})
export class ServicesCardComponent implements OnInit {

  @Input()
  data: any[];
  resourceLink = this.rest.resourceLink();
  
  constructor(private rest:RestService) { }

  ngOnInit() {
  }

}
