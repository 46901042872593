import { Component, Input } from '@angular/core';

@Component({
  selector: 'page-banner',
  templateUrl: './page-banner.component.html',
  styleUrls: ['./page-banner.component.scss']
})
export class PageBannerComponent {

  constructor() { }

  @Input() banner: string;
  @Input() title: string;

  ngOnInit() {
  }

}
