import { Component, Directive, ElementRef, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as Rx from 'rxjs'
import { SharedService } from '../../services/shared.service';
import { User } from '../../_models/user.model';
import { AuthService } from '../../services/auth.service';
import { RestService } from '../../services/rest.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  currentUser: User;
  currentUserSubscription?: Rx.Subscription;
  banners: any = [];

  adBannerVisible: boolean;
  dealsVisible: boolean;
  servicesVisible: boolean;
  parlonsVisible: boolean;
  appointmentsVisible: boolean;
  businessVisible: boolean;
  blogVisible: boolean;
  homeDealsVisible: boolean;
  homeEventsSectionVisibile: boolean;
  homeFeaturedDeals: boolean;

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute, 
    private rest: RestService,
    private _shared: SharedService,
    private ngZone: NgZone,
  ) {
      this.currentUserSubscription = this.auth.currentUser.subscribe(user => {
        this.currentUser = user;
      })

   }

  ngOnInit() {
    this.route.fragment.subscribe((fragment: string) => {
      const element = document.querySelector("#"+fragment);
      if(element) {
        element.scrollIntoView(true);
      }
    })
    this._shared.changeSearchArea('');
    this.getPageBanner();
    this.getVisiblePageSections();
  }

  getVisiblePageSections() {
    this.rest.homepageSectionVisibility().subscribe(data => {
      this.ngZone.run(() => {
        const sections = data['data'];

        this.adBannerVisible = sections.find(
          ({ section }) => section === 'App Store Section'
        ).visible === 0;

        this.dealsVisible = sections.find(
          ({ section }) => section === 'Coupon Section'
        ).visible === 0;

        this.servicesVisible = sections.find(
          ({ section }) => section === 'Home Service'
        ).visible === 0;

        this.parlonsVisible = sections.find(
          ({ section }) => section === 'Booking'
        ).visible === 0;

        this.appointmentsVisible = sections.find(
          ({ section }) => section === 'Browse'
        ).visible === 0;

        this.businessVisible = sections.find(
          ({ section }) => section === 'Parlon Business'
        ).visible === 0;

        this.blogVisible = sections.find(
          ({ section }) => section === 'Blogs'
        ).visible === 0;
        this.homeDealsVisible = sections.find(
          ({ section }) => section === 'Home Deals'
        ).visible === 0;
        this.homeEventsSectionVisibile = sections.find(
          ({ section }) => section === 'Event Section'
        ).visible === 0;
        this.homeFeaturedDeals = sections.find(
          ({ section }) => section === 'Featured Deals'
        ).visible === 0;
      });
    })
  }

  getPageBanner() {
    this.rest.getHomePageBanner().subscribe((data: {}) => {
      this.banners = data['banners'];
    });
  }

  ngOnDestroy() {
    if(this.currentUserSubscription) {
      // unsubscribe to ensure no memory leaks
      this.currentUserSubscription.unsubscribe();
    } 
  }
}
