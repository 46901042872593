// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  og_offers:'dev_OG_Offers',
  og_brands:'dev_OG_Brands',
  deals_price_desc:'dev_deals_price_desc',
  deals_price_asc:'dev_deals_price_asc',
  deals:'dev_Deals',
  parlons:'dev_Parlon',
  api: 'https://staging.parlon.ph/api/',
  booking: 'https://staging.parlon.ph/site/booking/',
  resource: "https://assets.parlon.ph/",
  imagekit: "https://ik.imagekit.io/parlon/",
  currency: "PHP",
  currencySign: "₱",
  showLocation: true,
  emptyNav: false,
  teaser: false,
  hide_user_success_message:false,
  country:'Philippines',
  typesense_url:'https://mda9w7iyf35nl20pp-1.a1.typesense.net/',
  typesense_key:'fSHUj8pOPMiIvDxrcmQl1dGwEXEp2nXV',
  maya_token: 'cGstMzFTaERwRVN2cUx4WUlpTzhmTm5QQ0RZbGIySlBUdDBuaXpxZzhSQmkwWTo=',
  maya_url:'https://pg-sandbox.paymaya.com/payments/v1/payment-tokens'
};
/*
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
