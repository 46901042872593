import { Component, OnInit } from '@angular/core';
import { NgxSiemaOptions, NgxSiemaService } from 'ngx-siema';
import { RestService } from '../../../../../services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'featured-parlons-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  parlons:any = [];
  resourceLink = this.rest.resourceLink();
  fields = "?fields=logo";
  isLoaded = false;


  constructor(private rest:RestService, private route: ActivatedRoute, private router: Router, private ngxSiemaService: NgxSiemaService) { }

  ngOnInit() {
    this.getParlons();
  }

  getParlons() {
    let app = this;
    this.parlons = [];
    this.rest.getParlons(this.fields).subscribe((data: {}) => {
      this.parlons = data['data'];
      app.isLoaded = true;
    });
  }

  options: NgxSiemaOptions = {
    selector: '.siema',
    duration: 200,
    easing: 'ease-out',
    perPage: {
      320: 1,
      768: 3,
      1024: 4,
    },
    startIndex: 0,
    draggable: true,
    threshold: 20,
    loop: false,
    onInit: () => {
      
    },
    onChange: () => {
    },
  };

}
