import { Component, Input  } from '@angular/core';

@Component({
  selector: 'main-banner',
  templateUrl: './main-banner.component.html',
  styleUrls: ['./main-banner.component.scss']
})
export class MainBannerComponent {

  constructor() { }

  @Input() banner: string;

  ngOnInit() {
  }

}
