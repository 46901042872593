import {trigger, animate, style, group, animateChild, query, stagger, transition} 
from '@angular/animations';

export const fadeAnimation = trigger('routerAnimations', [
  transition('* => *', [
    query(':self', 
    [
      style({ opacity: 0 })
    ], 
    { optional: true }
  ),

  query(':self',
    [
      style({ opacity: 0 }),
      animate('.5s', style({ opacity: 1 }))
    ], 
    { optional: true }
  )
  ])
]);