import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { environment } from '../../../../../environments/environment';

declare var Flickity: any;

@Component({
  selector: 'partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
  
  @Input() data;
  resource = environment.resource;

  constructor(
    private el: ElementRef,
  ) { }

  ngOnInit() {

    setTimeout(() => {
      const el = this.el.nativeElement;
      let elem = el.querySelector('.flickity-partners');
      let flkty = new Flickity(elem, {
        contain: true,
        cellAlign: 'center',
        autoPlay: true,
        imagesLoaded: true,
        pageDots: false,
        prevNextButtons: false,
        wrapAround: true,
        watchCSS: true
      });

      elem.addEventListener('pointerup', function() {
        flkty.playPlayer();
      });
    }, 500);
  }

}
