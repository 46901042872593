import { Component, OnInit, ElementRef, Input, HostListener, Inject } from '@angular/core';
import { ContentService } from '../../services/content/content.service';
import { Router, Event, NavigationStart, ActivatedRoute, NavigationEnd } from '@angular/router';
import * as Rx from 'rxjs';
import { WINDOW } from '@ng-toolkit/universal';
import { DealsService } from '../../services/deals/deals.service';
import { environment } from '../../../environments/environment';

declare var Flickity: any;

enum NavbarType {
  GLOBAL,
  SERVICES,
  DEALS,
}

@Component({
  selector: 'app-navbar-new',
  templateUrl: './navbar-new.component.html',
  styleUrls: ['./navbar-new.component.scss']
})
export class NavbarNewComponent implements OnInit {
  

  constructor(
    private el: ElementRef,
    private ContentService: ContentService,
    private router:Router,
    private DealsService:DealsService
  ) { }

  NavbarType = NavbarType;
  navbarTypeSubject$ = new Rx.BehaviorSubject<NavbarType>(NavbarType.GLOBAL);
  navbarType$ = this.navbarTypeSubject$.asObservable();
  contentLoaded = false;
  contentData;
  rxSubscription: Rx.Subscription;
  protected _onDestroy = new Rx.Subject<void>();
  selectedRoute="default";
  hideSearchDropDown = false;
  deal: object = {};
  cartCount:any = 0;
  resource = environment.resource;
  
  visible:boolean= false;


  navHidden = [
    {
      'route': '/beauty-bag-deals',
      'type': 'full'
    },
    {
      'route': '/beauty-bag-purchase',
      'type': 'dynamic'
    }
  ];
  
  

  

  
  ngOnInit() {

    this.DealsService.currentdeal.subscribe(deal => { 
      this.deal = deal;
    });

    this.DealsService.currentCountCart.subscribe(count => { 
      this.cartCount = count;
      if(!count) {
        this.cartCount = localStorage.getItem('bagCount');
      }
    });

    this.ContentService.pulledContent.subscribe((data) => {
      if(!(data === undefined || data.length == 0)) {
        this.contentLoaded = true;
        this.contentData = data;
      }
    });
    if(this.router.url.startsWith('/beauty-bag-deals')) {
      this.navbarTypeSubject$.next(NavbarType.DEALS);
    } else {
      this.navbarTypeSubject$.next(NavbarType.GLOBAL);
    }
    this.rxSubscription = this.router.events.subscribe(event => {   
      
      if (event instanceof NavigationEnd) {
        const { url } = event;
        if (url.startsWith('/beauty-bag-deals')) {
          this.navbarTypeSubject$.next(NavbarType.DEALS);
        } else {
          this.navbarTypeSubject$.next(NavbarType.GLOBAL);
        }
      }
    });


    let initialLoadUrl = this.router.url;
    if(initialLoadUrl.startsWith('/search/')){
      this.selectedRoute = 'search';
      this.hideSearchDropDown = true
      // navbar.style.display='none';
    }else if (initialLoadUrl.startsWith('/mobile/')) {
      this.selectedRoute = 'mobile';
      this.hideSearchDropDown = true;
      // navbar.style.display='none';
    }
    else{
      this.selectedRoute='default';
      this.hideSearchDropDown = false
      // navbar.style.display='block';
    }


    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const { url } = event;
        if (url.startsWith('/search/')) {
          this.selectedRoute = 'search';
          this.hideSearchDropDown = true;
          // navbar.style.display='none';
        }else if (url.startsWith('/mobile/')) {
          this.selectedRoute = 'mobile';
          this.hideSearchDropDown = true;
          // navbar.style.display='none';
        }
        else{
          this.selectedRoute='default';
          this.hideSearchDropDown = false;
          // navbar.style.display='block';
        }

        this.checkRoutesHidden();

      }else{
        this.checkRoutesHidden();
      }
      
    });

    if(this.router.url.startsWith('/beauty-bag-deals')) {
      this.navbarTypeSubject$.next(NavbarType.DEALS);
    } else {
      this.navbarTypeSubject$.next(NavbarType.GLOBAL);
    }
    this.rxSubscription = this.router.events.subscribe(event => {   
      
      if (event instanceof NavigationEnd) {
        const { url } = event;
        if (url.startsWith('/beauty-bag-deals')) {
          this.navbarTypeSubject$.next(NavbarType.DEALS);
        } else {
          this.navbarTypeSubject$.next(NavbarType.GLOBAL);
        }
      }
    });
  }

  showSearch() {
    const el = this.el.nativeElement;
    let search_bar = el.querySelector('#search-dialog-box');
    search_bar.classList.toggle('show');
    window.setTimeout(function () { 
      document.getElementById('search-input-field').focus(); 
    }, 500); 
  }

  checkRoutesHidden() {
    let visible = true;
    this.navHidden.forEach(route => {
      if(route['type'] == 'full') {
        if (this.router.url === route['route']){
          visible = false;
        }
      } else {
        if (this.router.url.includes(route['route'])){
          visible = false;
        }
      } 
    });

    this.visible = visible;
    // return visible;
  }

  isHomePage() {
    let home =  false;
    if(this.router.url == '/') {
      home = true;
    }
    return home;
  }

  // @HostListener('window:scroll', ['$event']) // for window scroll events
  // onScroll(event) {
  //   alert('asdasdasd');
  // }


  // calcWidth() {
  //   const el = this.el.nativeElement;

  //   var navwidth = 0;
  //   var morewidth = el.querySelector('.nav-desktop .overflow-menu').outerWidth(true);

  //   el.querySelector('.nav-desktop > li.nav-item:not(.overflow-menu)').each(function() {
  //     navwidth += el.querySelector(this).outerWidth(true);
  //   });

  //   var available_space = el.querySelector('nav.nav-desktop').outerWidth(true) - morewidth;

  //   if (navwidth > available_space) {
  //     var lastItem = el.querySelector('.nav-desktop > li.nav-item:not(.overflow-menu)').last();
  //     lastItem.attr('data-width', lastItem.outerWidth(true));
  //     lastItem.prependTo(el.querySelector('.nav-desktop > li.overflow-menu ul'));
  //     this.calcWidth();
    
  //   } else {
  //     var firstMoreElement = el.querySelector('.nav-desktop > li.overflow-menu ul > li').first();

  //     if (navwidth + firstMoreElement.data('width') < available_space) {
  //       firstMoreElement.insertBefore(el.querySelector('.nav-desktop li.overflow-menu'));
  //     }
  //   }

  //   let overflow_menu = el.querySelector('.overflow-menu');

  //   if (el.querySelector('.overflow-menu li').length > 0) {
  //     overflow_menu.classList.remove('hidden');
  //   } else {
  //     overflow_menu.classList.add('hidden');
  //   }
  // }

  ngOnDestroy() {
    if (this.rxSubscription) {
      this.rxSubscription.unsubscribe();
    }

    this._onDestroy.next();
    this._onDestroy.complete();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    // Parlon deals mobile nav on scroll
    const scrollable = document.querySelector('.scrollable')
    const isScrolled = "is-scrolled";
    const dealsCheckpoint = document.getElementById('deals-checkpoint');
    const el = this.el.nativeElement;
    const navbar = el.querySelector('.navbar.is-hidden-on-home');
    let search_bar = el.querySelector('#search-dialog-box');
    let scrollTargetPosition = 150;
    if (dealsCheckpoint != null) {
      const elementOffsetTop = dealsCheckpoint.offsetTop;
      if (elementOffsetTop >= window.pageYOffset) {
        scrollable.classList.remove(isScrolled);
      } else {
        scrollable.classList.add(isScrolled);
      }
    }

    if(this.router.url == '/') {
      try {
        if (navbar.getAttribute('aria-hidden') == 'true' && window.scrollY > scrollTargetPosition) {
          navbar.setAttribute('aria-hidden', false);
          
          navbar.classList.add('showing');
          setTimeout(() => {
            navbar.classList.add('show');
            navbar.classList.remove('showing');
          }, 200);
  
        } else if (navbar.getAttribute('aria-hidden') == 'false' && window.scrollY < scrollTargetPosition) {
          navbar.setAttribute('aria-hidden', true);
  
          navbar.classList.add('showing');
          navbar.classList.remove('show');
          search_bar.classList.remove('show');
          setTimeout(() => {
            navbar.classList.remove('showing');
          }, 200);
        }
      } catch(e) { }
    }

  }

}
