import { Component, OnInit } from '@angular/core';3
import { Form, FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { DealsService } from '../../services/deals/deals.service';
import { environment } from '../../../environments/environment';
import { UserAccountService } from '../../services/user-account/user-account.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import * as Rx from 'rxjs';
import { L } from '@angular/core/src/render3';
import Swal from 'sweetalert2';
import { NetcoreService } from '../../services/netcore/netcore.service';
import { CandiesService } from '../../services/candies/candies.service';



@Component({
  selector: 'app-beauty-bag-checkout',
  templateUrl: './beauty-bag-checkout.component.html',
  styleUrls: ['./beauty-bag-checkout.component.scss']
})
export class BeautyBagCheckoutComponent implements OnInit {

  bagId = localStorage.getItem('bagId');
  resource = environment.resource;
  isLoaded = false;
  loadingUpdate = false;
  promoCodeLoading = false;
  response:object;
  editForm: FormGroup;
  api_token = '';

  candiesToEarn:object;

  dealsSubject$ = new Rx.BehaviorSubject([]);
  deals$ = this.dealsSubject$.asObservable();
  randomdeals:any = [];  
  candiesAvailable:number = 0;
  constructor(
    private DealsService: DealsService,
    private smartModal: NgxSmartModalService,
    private UserAccountService: UserAccountService,
    private NetcoreService:NetcoreService,
    private candiesService: CandiesService
  ) { }

  applyCandies(data){
    // alert(1);
    this.candiesService.updateBooleanValue(false);
    this.response = data;
    this.candiesAvailable = data['candies_available'];
    this.dealsSubject$.next(data['deals']);
  }

  ngOnInit() {
    let page = this;
    page.isLoaded = false;
    this.editForm = new FormGroup({
      'RedeemerFormGroup': new FormGroup({
        'email': new FormControl(null, [Validators.required, Validators.email]),
        'first_name': new FormControl(null, Validators.required),
        'last_name': new FormControl(null, Validators.required),
        're_enter_email': new FormControl(null, [Validators.required, Validators.email]),
        'contact_number': new FormControl(null, Validators.required),
        'surprise': new FormControl(false),
        'bag_id': new FormControl(null, Validators.required),
        'item_id': new FormControl(null, Validators.required),
        'redeemer_id': new FormControl(null, Validators.required)
      }),
    });
    if(this.bagId == null || this.bagId == 'null') {
      this.dealsSubject$.next([]);
      this.DealsService.getRandomDeals().subscribe((data: {}) => {
        page.randomdeals = data['data'];      
        page.isLoaded = true;
      });
    } else {
      this.UserAccountService.getLoggedInUser.subscribe(data=>{
        
        if(data){
          this.api_token = data.data;
        }
        this.DealsService.getBagItems(this.bagId, '?api_token='+this.api_token).subscribe((data: {}) => {
          if(data['success'] == true) {
            page.response = data['data'];
            page.candiesToEarn = data['data'];
            this.candiesAvailable = data['data']['candies_available'];
            // console.log('candies_available:', this.candiesAvailable);
            // page.deals = data['data']['deals'];
            this.dealsSubject$.next(data['data']['deals']);
          } else {
            localStorage.removeItem('bagCount');
            localStorage.removeItem('bagId');
            localStorage.removeItem('buyerInfo');
          }
          this.DealsService.getRandomDeals().subscribe((data: {}) => {
            page.randomdeals = data['data'];
          });
          page.isLoaded = true;
        }, err => {
          console.log(err);
          localStorage.removeItem('bagCount');
          localStorage.removeItem('bagId');
          localStorage.removeItem('buyerInfo');
          page.isLoaded = true;
        });
      })
    }
    
  }

  deleteItem(deal,redeemer) {
    Swal.fire({
      title: 'Are you sure you want to remove this from your Beauty Bag?',
      showCancelButton: true,
      confirmButtonColor: "#46b0a9",
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result['value']) {
        const page = this;
        const payload = {
          'redeemer':true,
          'item_id':deal['id'],
          'redeemer_id':redeemer['id']
        }
        if(this.api_token != '')
          payload['api_token'] = this.api_token;
        this.loadingUpdate = true;
        this.DealsService.deleteBagItem(this.bagId,payload).subscribe((data: {}) => {
          this.loadingUpdate = false;
          page.response = data;
          page.candiesToEarn = data;
          localStorage.setItem('bagCount',data['deals'].length);
          page.DealsService.changeCartCount(data['deals'].length);
          this.dealsSubject$.next(data['deals']);
          this.NetcoreService.dispatch('removed from bag', {
            "dealid": deal['deal_id'],
            "deal": deal['name'],
            "dealcategory": deal['deal_categories'],
            "dealprice": deal['deal_price'],
            "dealsubcategory": deal['deal_subcategories'].toString(),
            "merchant": deal['parlon'],
            "dealbranch": redeemer['branch'],
          });
          if(data['deals'].length == 0) {
            localStorage.removeItem('buyerInfo');
            localStorage.removeItem('bagCount');
            localStorage.removeItem('bagId');
          }
        });
      }
    });
  }

  toggleSection(section) {
    const el = document.querySelectorAll('.'+section) // Using a class instead, see note below.
    for (let i = 0; i < el.length; i++) {
      el[i].classList.toggle('collapsed');
    }
  }

  showEditModal(deal,redeemer) {
    this.editForm.controls.RedeemerFormGroup.patchValue({
      email:redeemer['email'],
      re_enter_email:redeemer['email'],
      first_name:redeemer['firstname'],
      last_name:redeemer['lastname'],
      contact_number:redeemer['mobile_number'],
      surprise:redeemer['surprise'],
      bag_id:this.bagId,
      redeemer_id:redeemer['id'],
      item_id:deal['id']
    });
    this.smartModal.setModalData({deal,redeemer
    }, 'editModal', true);    
    this.smartModal.getModal('editModal').open();
  }

  editValidity()
  {
    const app = this;
    let valid = true;
    // Object.keys(app.editForm.controls.personalDetailsFormGroup['controls']).forEach(function(key) {
    //   app.editForm.controls.personalDetailsFormGroup['controls'][key].markAsTouched();
    // });c
    if(this.smartModal.getModal('editModal').getData().deal['buying_for'] == 'se') {
      if(this.editForm.controls.RedeemerFormGroup['controls'].surprise.value == true) {
        this.editForm.controls.RedeemerFormGroup['controls'].email.setValidators(null);
        this.editForm.controls.RedeemerFormGroup['controls'].re_enter_email.setValidators(null);
        this.editForm.controls.RedeemerFormGroup['controls'].contact_number.setValidators(null);
      } else {
        this.editForm.controls.RedeemerFormGroup['controls'].email.setValidators([Validators.required, Validators.email]);
        this.editForm.controls.RedeemerFormGroup['controls'].re_enter_email.setValidators([Validators.required, Validators.email]);
        this.editForm.controls.RedeemerFormGroup['controls'].contact_number.setValidators(Validators.required);
      }
      this.editForm.controls.RedeemerFormGroup['controls'].email.updateValueAndValidity();
      this.editForm.controls.RedeemerFormGroup['controls'].re_enter_email.updateValueAndValidity();
      this.editForm.controls.RedeemerFormGroup['controls'].contact_number.updateValueAndValidity();
    }
    
    if(app.editForm.controls.RedeemerFormGroup.status == "INVALID") {
      valid = false;
    }
    return valid;
  }

  updateItem() {
    const page = this;
    if(this.editValidity()) {
      const data = {
        "item_id" : this.editForm.value.RedeemerFormGroup.item_id,
        "redeemer" : {
            "id": this.editForm.value.RedeemerFormGroup.redeemer_id,
            "surprise": this.editForm.value.RedeemerFormGroup.surprise, 
            "firstname" : this.editForm.value.RedeemerFormGroup.first_name,
            "lastname" : this.editForm.value.RedeemerFormGroup.last_name,
            "email_address": this.editForm.value.RedeemerFormGroup.email, 
            "mobile_number": this.editForm.value.RedeemerFormGroup.contact_number
        }
      };
      if(this.api_token != '')
       data['api_token'] = this.api_token;
      this.loadingUpdate = true;
      this.DealsService.editBagItem(this.bagId,data).subscribe((data: {}) => {
        this.loadingUpdate = false;
        page.response = data['data'];
        this.dealsSubject$.next(data['data']['deals']);
        page.smartModal.getModal('editModal').close();
      });
    }
  }

  templateCardFormat(data) {
    const deal = {
      "deal_name": data['name'],
      "slug": data['slug'],
      "deal_price": data['deal_price'],
      "orig_price": data['original_price'],
      "primary_photo": data['primary_photo'],
      "discount_type": data['discount_type'],
      "discount_value": data['discount_value'],
      "parlon_name": data['parlon']['business_name'],
      "parlon_slug": data['parlon']['slug'],
      "parlon_logo": data['parlon']['logo'],
    }
    return deal 
  }

  getLocations(data) {
    let locations = [];
    data.forEach(e => {
      locations.push(e['location_name']);
    });
    return locations;
  }

  applyCode(data) {
    let page = this;
    let error = document.getElementById('promo-error');
    let signin_error = document.getElementById('login-error');    
    if(data != null) {
      let payload = {
        "promo_code" : data,
        "bag_id": this.bagId,
        "device_used": "web"
      }
      if(this.api_token != '')
          payload['api_token'] = this.api_token;
      page.promoCodeLoading = true;
      this.DealsService.applyPromo(payload).subscribe(data  => {
        page.promoCodeLoading = false;
        if(data['success'] == true) {
          error.classList.add("d-none");
          signin_error.classList.add("d-none");
          page.response = data['data'];
          this.dealsSubject$.next(data['data']['deals']);
        } else {
          error.classList.remove("d-none");
          error.innerHTML = data['message'];
        }
        
      },
      err => {
        page.promoCodeLoading = false;
        console.log(err);
        error.classList.remove("d-none");
      });
    } else {
      const payload = {
        "bag_id": this.bagId
      }
      if(this.api_token != '')
          payload['api_token'] = this.api_token;
      page.promoCodeLoading = true;
      this.DealsService.removePromo(payload).subscribe((data: {}) => {
        page.promoCodeLoading = false;
        page.response = data;
        this.dealsSubject$.next(data['deals']);
      });
    }
  }

}
