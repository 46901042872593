import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'section-banner',
  templateUrl: './section-banner.component.html',
  styleUrls: ['./section-banner.component.scss']
})
export class SectionBannerComponent implements OnInit {

  constructor() { }

  @Input() banner: string;
  @Input() title: string;
  @Input() class: string;

  ngOnInit() {
  }

}
