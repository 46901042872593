import { Component, OnInit, Input, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from '../../services/settings/settings.service';
import { BookingService } from '../../services/booking/booking.service';
import { environment } from '../../../environments/environment';
import { DealsService } from '../../services/deals/deals.service';
declare var My2c2p: any;

@Component({
  selector: 'app-booking-payment',
  templateUrl: './booking-payment.component.html',
  styleUrls: ['./booking-payment.component.scss']
})
export class BookingPaymentComponent implements OnInit {
  
  selected_channel:string="";
  cc: object;
  isTransactionFailed = false;
  payment_channels;
  resource = environment.resource;
  checkoutForm: FormGroup;
  data;
  isLoaded = false;
  loading = false;
  terms = false;
  error_message = '';

  constructor(
    private SettingsService: SettingsService,
    private BookingService: BookingService,
    private DealsService:DealsService,
    private route: ActivatedRoute,
    private el: ElementRef
  ) {
    this.cc = {
      cardnumber: '',
      month: '',
      year: '',
      cvv: '',
    };
   }

  ngOnInit() {
    this.checkoutForm = new FormGroup({
      'paymentFormGroup': new FormGroup({
        'promoCode': new FormControl(null),
        'creditCard': new FormControl(null, Validators.required),
        'ccMonth': new FormControl(null, Validators.required),
        'ccYear': new FormControl(null, Validators.required),
        'creditCardCvv': new FormControl(null, Validators.required),
        'paymentChannel': new FormControl(null, Validators.required),
        'eVoucherPolicy': new FormControl(false,Validators.requiredTrue),
      })
    });
    let refId = this.route.snapshot.paramMap.get("reference_id");
    this.SettingsService.getPaymentChannels('booking').subscribe(data=>{
      if(data != null) {
        this.payment_channels = data;
      }
    });
    this.BookingService.getInvoice(refId).subscribe(data=>{
      if(data != null) {
        this.data = data;
        console.log(data);
        this.isLoaded = true;
      }
    });

  }

  paymentOptionClick(target) {
    // let element: HTMLElement = document.getElementById(target) as HTMLElement;
    // console.log(element);

    this.selected_channel = target;

    this.checkoutForm.controls.paymentFormGroup.patchValue({
      paymentChannel:target
    });
  }

  onSubmit(){
    // return
    let app = this;
    
    let payload = {
      "reference_id":this.data['invoice'],
      "securePayToken":'',
      "payment_channel_code":this.selected_channel
      // "securePayToken": "00acPPKYaeF0AhGoT86uB/E0imxWFkTPJ//fqVNMLgIs0uUIok3bScExtsjOYDtcAJ5PbsUxpjzPAi/pvWURityHizUrj9i9gi8XNGvgE+/OwSGNDHVM0cAXygdwWZAzbLbuNJ+heV0PwknTuLkUycAq5rWvgapSCsoQUgcdcm7UA/E=U2FsdGVkX18Wup6LnK2yNcKPz5R4ctD6qlvixexe2To="
    }

    if((this.selected_channel == 'parlon_credit_card' || this.selected_channel == 'parlon_maya')) {

      if(app.checkoutForm.controls.paymentFormGroup.status == null) {
        app.scrollToFirstInvalidControl();
        return false;
      }
     
      // end of validate
      app.cc['cardnumber'] = app.checkoutForm.value.paymentFormGroup.creditCard.replace(/\s/g, '');
      app.cc['cvv'] = app.checkoutForm.value.paymentFormGroup.creditCardCvv.replace(/\s/g, '');
      app.cc['month'] = app.checkoutForm.value.paymentFormGroup.ccMonth;
      app.cc['year'] = app.checkoutForm.value.paymentFormGroup.ccYear.toString();

    }

     setTimeout(()=>{
      My2c2p.getEncrypted("cc-form",function(encryptedData,errCode,errDesc) {      
        payload.securePayToken = encryptedData.encryptedCardInfo;            
      });


      if(payload['payment_channel_code'] == 'parlon_maya') {
        let maya_fields = {
          card: {
            number:this.checkoutForm.value.paymentFormGroup.creditCard.replace(/\s/g, ''),
            expMonth:this.checkoutForm.value.paymentFormGroup.ccMonth,
            expYear:this.checkoutForm.value.paymentFormGroup.ccYear.toString(),
            cvc:this.checkoutForm.value.paymentFormGroup.creditCardCvv.replace(/\s/g, '')
          }
        };
        this.DealsService.getMayaToken(maya_fields).subscribe((response) => {
          payload['securePayToken'] = response['paymentTokenId'];
            app.requestPayment(payload);
        });
        return;
      }
      app.requestPayment(payload);
     },200)
          
  }

  requestPayment(payload) {
    let app = this;
    this.loading = true;
    this.BookingService.requestInvoicePayment(payload).subscribe(data => { 
      console.log(data);
      this.loading = false;
      setTimeout(() => {
        if(data['status'] == true && data['isRedirect'] == true){          
          window.location.href = data['response']['data'];
        } else if(data['status'] == false) {
          app.isTransactionFailed = true;
          app.error_message = data['message'];
        }
      }, 1000);
      
    });
  }

  validate() {    
    let valid = true;

    if(this.selected_channel == '')
      valid = false;

    // if(this.terms == false)
    //   valid = false;

    return valid;
  }

  scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelectorAll(
      "mat-select.ng-invalid, input.ng-invalid, mat-option.ng-invalid, mat-radio-group.ng-invalid, mat-radio-button.ng-invalid"
    );
    firstInvalidControl[0].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
  }

}
