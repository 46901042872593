import { Component, OnInit, Input } from '@angular/core';
import { RestService } from '../../services/rest.service';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Component({
  selector: 'blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.scss']
})
export class BlogCardComponent implements OnInit {

  constructor(
    private rest: RestService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  @Input() data: any = [];
  resourceLink = this.rest.resourceLink();

  ngOnInit() {
  }

}
