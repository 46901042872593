import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bag-items',
  templateUrl: './bag-items.component.html',
  styleUrls: ['./bag-items.component.scss']
})
export class BagItemsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
