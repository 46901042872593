import { Component, OnInit, Input } from '@angular/core';
import { RestService } from '../../services/rest.service';

@Component({
  selector: 'deals-card',
  templateUrl: './deals-card.component.html',
  styleUrls: ['./deals-card.component.scss']
})
export class DealsCardComponent implements OnInit {

  @Input()
  deal: any[];
  resourceLink = this.rest.resourceLink();
  
  constructor(
    private rest:RestService
    ) { }

  ngOnInit() {
    // console.log(this.deal)
  }

}
