import { Component } from '@angular/core';

@Component({
  selector: 'home-featured-parlons',
  templateUrl: './featured-parlons.component.html',
  styleUrls: ['./featured-parlons.component.scss']
})
export class FeaturedParlonsComponent {

  constructor() { }

}
