import { Component, OnInit } from '@angular/core';
import { RestService } from '../../../../../services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'featured-parlons-grid',
  templateUrl: './featured-parlons-grid.component.html',
  styleUrls: ['./featured-parlons-grid.component.scss']
})
export class FeaturedParlonsGridComponent implements OnInit {

  parlons:any = [];
  resourceLink = this.rest.resourceLink();
  // fields = "?fields=logo";
  relationship = "?relationships&hasBooking";
  isLoaded = false;

  constructor(
    private rest:RestService, 
    private route: ActivatedRoute, 
    private router: Router) { }

  ngOnInit() {
    this.getParlonsWithBooking();
  }

  getParlonsWithBooking() {
    let app = this;
    this.parlons = [];
    this.rest.getParlonsWithBooking(this.relationship).subscribe((data: {}) => {
      this.parlons = data['data'];
      this.parlons.sort(() => Math.random() - 0.5);
      app.isLoaded = true;
    });
  }

}
